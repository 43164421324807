<template>
  <div>
    <div v-if="$route.name === 'newcompetition.final'" class="finish-component" :class="{
        'background': classSwimming,
        'background-basketball': classBasketball
      }">
      <div class="content">
        <h1 v-if="title"> {{ title }} </h1>
        <p v-if="description" v-html="description"></p>
      </div>

      <footer class="footer">
        <a-button :to="'/'" @click.prevent="reset" class="footer__button">
          {{ $t('finish.tohome') }}
        </a-button>
      </footer>
    </div>

    <div v-else class="finish-component" :class="classes">
      <div class="content">
        <h1 v-if="title"> {{ title }} </h1>
        <p v-if="description"> {{ description }} </p>
      </div>

      <router-link to="/" class="back">
        {{ $t('finish.tohome') }}
      </router-link>
    </div>
  </div>
</template>

<script>

import newcompetition from '@/mixins/newcompetition'

export default {
  name: 'Finish',
  mixins: [
    newcompetition
  ],
  props: {
    title: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    background: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    reset() {
      this.$store.dispatch('newcompetition/reset')
      this.$router.push('/')
    }
  },
  computed: {
    classSwimming() {
      return this.selectedSport?.name === 'Swimming';
    },
    classBasketball() {
      return this.selectedSport?.name === 'Basketball';
    },
    classes() {
      const { background } = this

      return { background }
    }
  }
}
</script>

<style scoped lang='stylus'>
  .footer {
    position: absolute
    bottom 0
    left 0
    width 100%
    background: #141723;
    padding 16px

    &__button {
      background-color: #6293FF;
      border: 0
    }
  }
</style>