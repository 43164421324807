<template>
  <view-container name="record">
    <view-content :class="classes">
      <div class="camera">
        <video autoplay playsinline ref="video"></video>
      </div>

      <template v-if="!showUpload">
        <div class="shot" v-if="confirmed && camera">
          <div class="shot-inner"></div>
        </div>

        <div class="controls">
          <router-link to="/" class="back">
            <Icon type="arrow-left"/>
          </router-link>

          <div class="timer">
            {{ timer }}
          </div>

          <a class="flashlight">
            <Icon type="flashlight"/>
          </a>

          <a class="settings">
            <Icon type="settings"/>
          </a>
        </div>



        <div class="confirm" v-if="!camera">
          <span class="settings"> {{ $t('pages.record.allow') }} </span> 
          <span class="settings"> {{ $t('pages.record.settings') }} </span>


          <a @click="() => { this.camera = !this.camera }"> {{ $t('pages.record.ok') }} </a>
        </div>
        <div class="confirm" v-if="!confirmed && camera">
          <span> {{ $t('pages.record.confirm') }} </span>

          <a @click="() => { this.confirmed = !this.confirmed }"> {{ $t('pages.record.ok') }} </a>
        </div>

        <div class="play" v-if="confirmed" @click="record"></div>
      </template>

      <Upload v-if="showUpload" :chunks="chunks" @close="close"/>
    </view-content>
  </view-container>
</template>

<script>

import Icon from '@/components/Icon'
import Upload from '@/components/Record/Upload'
import moment from 'moment-timezone'

export default {
  name: 'Record',
  components: {
    Icon,
    Upload
  },
  data () {
    return {
      stream: null,
      recorder: null,
      recording: false,
      confirmed: false,
      camera: false,
      chunks: []
    }
  },
  mounted () {
    this.getStream()
  },
  beforeDestroy () {
    this.removeStream()
  },
  computed: {
    showUpload () {
      return !this.recording && this.chunks.length
    },
    classes () {
      return {
        upload: this.showUpload,
        recording: this.recording,
        confirmed: this.confirmed
      }
    },
    timer () {
      return moment.duration(this.chunks.length, 'seconds').format('mm:ss', { trim: false })
    }
  },
  methods: {
    async getStream () {
      const constraints = {
        audio: true,
        video: {
          facingMode: { ideal: 'environment' },
          aspectRatio: 1,
          // width: { exact: 150 },
          // height: { exact: 200 }
        }
      }

      try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints)

        this.stream = stream

        this.setVideo()
        this.setRecorder()
      } catch (err) {
        console.log(err)
      }
    },
    removeStream () {
      const { stream } = this

      if (!stream) {
        return
      }

      for (const track of stream.getTracks()) {
        track.stop()
      }
    },
    setVideo () {
      const video = this.$refs.video
      const stream = this.stream

      try {
        video.srcObject = stream
      } catch (error) {
        video.srcObject = URL.createObjectURL(stream)
      }
    },
    setRecorder () {
      const stream = this.stream

      this.recorder = new MediaRecorder(stream)

      this.recorder.ondataavailable = ({ data }) => {
        this.chunks.push(data)
      }
    },
    play () {
      this.recording = true

      this.recorder.start(1000)
    },
    pause () {
      this.recorder.stop()

      this.recording = false
    },
    record () {
      this.recording ? this.pause() : this.play()
    },
    close () {
      this.chunks = []
    }
  }
}
</script>
