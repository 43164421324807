<template>
  <div class="upload-component">
    <div class="controls" v-if="showControls">
      <router-link to="/" class="back">
        <Icon type="arrow-left"/>
      </router-link>
    </div>

    <div class="video" v-if="!uploaded">
      <div class="timer"> {{ timer }} </div>

      <div class="error" v-if="error"> {{ $t('pages.record.upload.error') }} </div>

      <div class="video-container">
        <video playsinline controls="controls" ref="video"></video>
      </div>

      <div class="actions" v-if="!uploading">
        <a-button @click="upload">
          <Icon :size="20" type="upload"/>

          {{ $t('pages.record.upload.upload') }}
        </a-button>

        <a-button class="secondary" @click="close">
          <Icon :size="20" type="reload"/>

          {{ $t('pages.record.upload.refilm') }}
        </a-button>
      </div>

      <span class="uploading" v-if="uploading">
        {{ $t('pages.record.upload.uploading') }}
      </span>
    </div>

    <Finish
      v-if="uploaded"
      :title="$t('pages.record.upload.success.title')"
      :description="$t('pages.record.upload.success.description')"
      />

    <div class="links">
      <router-link to="/" class="back" v-if="!uploading && !uploaded">
        {{ $t('pages.record.upload.back') }}
      </router-link>

      <a @click="close" class="cancel" v-if="uploading">
        {{ $t('pages.record.upload.cancel') }}
      </a>
    </div>
  </div>
</template>

<script>

import Icon from '@/components/Icon'
import Finish from '@/components/Finish'
import moment from 'moment-timezone'
import axios from '@/plugins/axios'

export default {
  name: 'Upload',
  components: {
    Icon,
    Finish
  },
  props: {
    chunks: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      error: false,
      video: null,
      uploaded: false,
      cancelToken: null
    }
  },
  computed: {
    timer () {
      return moment.duration(this.chunks.length, 'seconds').format('mm:ss', { trim: false })
    },
    uploading () {
      return !!this.cancelToken
    },
    showControls () {
      return !this.uploading && !this.uploaded
    }
  },
  created () {
    this.createBlob()
  },
  mounted () {
    this.load()
  },
  methods: {
    createBlob () {
      this.blob = new Blob(this.chunks, {
        type: 'video/mp4'
      })
    },
    async load () {
      let url = await URL.createObjectURL(this.blob)

      this.$refs.video.setAttribute('src', url)

      this.$refs.video.play()
    },
    async upload () {
      const cancelToken = axios.CancelToken.source()

      this.cancelToken = cancelToken

      try {
        const file = new File([this.blob], 'test.mp4', {
          type: 'mp4'
        })

        const id = this.$route.params.id

        let formData = new FormData()

        formData.append('video', file)
        formData.append('competition_id', this.$route.params.id)

        await axios.post(`/profile/competitions/${id}/upload-video`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            // },
            // onUploadProgress: (e) => {
              // console.log(e)
              // this.progress = Math.round(e.loaded * 100 / e.total)
              // console.log('this.progress', this.progress)
            },
            cancelToken: cancelToken.token
          }
        )

        this.uploaded = true
      } catch (e) {
        console.log(e)

        this.error = true

        this.load()
      }

      this.cancelToken = null
    },
    cancel () {
      this.cancelToken.cancel()

      this.cancelToken = null

      this.close()
    },
    close () {
      this.$emit('close', true)
    }
  }
}
</script>
